import { useStaticQuery, graphql } from 'gatsby'

export const getHeritageSeriesData = () => {
  const { allStrapiHeritageSeries } = useStaticQuery(heritageSeriesDataQuery)
  return allStrapiHeritageSeries
}

const heritageSeriesDataQuery = graphql`
  query {
    allStrapiHeritageSeries(sort: {fields: date, order: ASC}) {
      nodes {
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        excerpt: body {
          data {
            excerpt: body
          }
        }
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(width: 300, height: 470)
          }
        }
        button01 {
          url
          name
          id
        }
      }
    }
  }
`
