import React from 'react'
import { Link } from 'gatsby'
import { Box, Text, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { getStaticPageSeoDetails } from '@utils-blog'
import Card from '@components/Card'
import { getHeritageSeriesData } from '../../helpers/nr-heritage-series.helper'
import CardList from '@components/CardList/CardList'
import { Hero } from '@layout'
import { getHeritageSeriesHeroData } from '../../helpers/nr-heritage-series-hero.helper'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import HeroComponent from '../../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

/**
 * Shadow me to add your own content
 */

const styles = {
  body: {
    fontWeight: `normal`,
    '> p': {
      mt: 0,
      ml: '0 !important',
      color: '#fff'
    }
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  boxs: {
    display: `flex`,
    flexWrap: `wrap`
  },
  box: {
    flex: `0 0 33.33%`,
    p: 20,
    '> div': {
      height: '100%'
    }
  },
  buttons: {
    m: 1
  },
  wrapper: {
    textAlign: `center`
  },
  avatarWrapper: {
    mb: 4
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  },
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `bold`,
    p: 10
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`
    }
  },
  button: {
    display: `block`,
    mt: `auto`
  },
  bgBoxWrap: {
    background: 'linear-gradient( 0deg, #000000 0%, transparent 100% )',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  bgBox: {
    display: `flex`,
    flexDirection: `column`,
    flexWrap: `auto`,
    justifyContent: `end`,
    position: 'absolute',
    top: 50,
    left: '50%',
    transform: 'translate(-50%,0)',
    right: 0,
    bottom: 0,
    pt: 10,
    pb: '4rem',
    pr: '2rem',
    pl: '2rem',
    width: '100%',
    maxWidth: '1140px'
  },
  textColor: {
    color: '#fff',
    fontFamily: `'Spectral',serif`
  }
}

export default props => {
  const data = getHeritageSeriesData()

  let seoData = getStaticPageSeoDetails('shared.heritage-series')
  const heritageSeoData = {
    ...seoData,
    Heritage: {
      ...seoData?.Heritage,
      keywords: seoData?.Heritage?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={heritageSeoData?.Heritage?.metaTitle}
        description={heritageSeoData?.Heritage?.metaDescription}
        keywords={heritageSeoData?.Heritage?.keywords}
        thumbnail={heritageSeoData?.Heritage?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'heritageSeries'}
            {...props}
            callFrom='Heritage Series'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Hero>
      <Main
          sx={{
            display: [null, `flex`],
            flexDirection: [`column`, null, null, `row`]
          }}
          >
        <CardList
          isPartner={true}
          isHeritage={true}
          nodes={data.nodes}
          columns={[1]}
          variant={['horizontal-md', 'horizontal']}
          // title='Trending Now'
        />
        </Main>
        </Hero>
    </Layout>
  )
}
